<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="es" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Últimas aplicaciones GB MOD 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      Descargar
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`en-${item.name}`}">
                      Descargar
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>¿Qué son los WhatsApp Mod APKs?</h2>
              <p>
              Mientras que la aplicación oficial de WhatsApp evoluciona con actualizaciones, su ritmo y características limitadas a menudo no satisfacen las demandas de los usuarios. Para llenar este vacío, desarrolladores habilidosos han modificado la aplicación original, integrando características emocionantes y prácticas más allá de las que ofrece WhatsApp. Como resultado, se desarrollaron mods populares como GB WhatsApp, Fouad WhatsApp y Yo WhatsApp.
              </p>
              <p>
              En esta página, encontrarás información detallada sobre estos WhatsApp Mod APKs. Proporcionamos enlaces de descarga seguros, guías paso a paso e introducciones de características para ayudarte a explorar y disfrutar estas aplicaciones modificadas.
              </p>
              <p>
              ¡No dudes en descargar el WhatsApp Mod APK que más te guste ahora!
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Política de Privacidad</a>
          </div>
          <div class="copyright-bar">
            2025 © Todos los derechos reservados <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Descargar GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'es',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      let params = window.location.search;
      window.location.href = '/es/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>

<template>
<div id="app" class="plus home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
  <HeadNav selectedLanguage="es" page="plus" />

  <div id="page" class="site grid-container container hfeed">
    <div id="content" class="site-content">
      <div id="primary" class="content-area">
        <main id="maincontent" class="site-main">
          <article>
            <div class="inside-article">
              <div class="entry-content" itemprop="text">
                <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                  <span aria-hidden="true" class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient" style="background: linear-gradient(180deg, #1538b4 0%, rgb(0, 0, 0) 99%)" />
                  <!-- <div role="img" class="wp-block-cover__image-background wp-image-622 has-parallax" style="background-position: 50% 50%; background-image: url(wp-content/uploads/2022/12/BG-2K.webp)"></div> -->
                  <div class="wp-block-cover__inner-container">
                    <!-- 首图logo -->
                    <div class="wp-block-image ic sdw">
                      <figure class="aligncenter size-full">
                        <img decoding="async" width="512" height="512" src="../assets/blog6-logo-3.webp" alt="GB WhatsApp APK" class="wp-image-615">
                      </figure>
                    </div>
                    <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                      WhatsApp Plus
                    </h1>

                    <div class="code-block code-block-4" style="margin: 8px auto; text-align: center; display: block; clear: both">
                      <p class="has-text-align-center v">
                        Versión: <strong>18.30</strong> | Tamaño: <strong>77MB</strong>
                      </p>
                    </div>

                    <p class="has-text-align-center d">
                      WhatsApp Plus fue desarrollado basado en el WhatsApp oficial, que cuenta con más de dos mil millones de usuarios en todo el mundo. Como uno de los Mods de WhatsApp, permite a los usuarios leer mensajes eliminados, ocultar los dos ticks, cambiar temas y fuentes, configurar respuestas automáticas y más funciones personalizables.
                    </p>

                    <div class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                      <div class="wp-block-button">
                        <router-link class="wp-block-button__link wp-element-button" :to="{name:`${selectedLanguage}-download`}">
                          Descargar WhatsApp Plus
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>

                  <div id="beesads"></div>

                  <!-- Tabla de contenidos -->
                  <div id="toc_container" class="no_bullets">
                    <section class="table-content" v-show="!tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Tabla de Contenidos
                        <img src="../assets/minus.svg" alt="menos">
                      </div>

                      <ul class="toc_list">
                        <li><a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> Detalles de la App WhatsApp Plus</a></li>
                        <li><a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> Capturas de Pantalla</a></li>
                        <li><a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Necesidad de WhatsApp Plus</a></li>
                        <li><a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Funciones de WhatsApp Plus</a></li>
                        <li><a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Nuevas Funciones Agregadas en 2024</a></li>
                        <li><a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> WhatsApp Plus vs WhatsApp Oficial</a></li>
                        <li><a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span> Respaldo de WhatsApp Plus</a></li>
                        <li><a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Requisitos para WhatsApp Plus</a></li>
                        <li><a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> ¿Cómo Descargar WhatsApp Plus?</a></li>
                        <li><a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> ¿Cómo Actualizar WhatsApp Plus?</a></li>
                        <li><a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> Versión Oficial 18.30 de WhatsApp Plus</a></li>
                        <li><a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span> Próxima Versión 2025</a></li>
                        <li><a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Versiones Antiguas de WhatsApp Plus</a></li>
                        <li><a href="#TBC_13"><span class="toc_number toc_depth_12">14.</span> WhatsApp Plus para iPhone</a></li>
                        <li><a href="#TBC_14"><span class="toc_number toc_depth_12">15.</span> Descargar WhatsApp Plus para PC/Portátil</a></li>
                        <li><a href="#TBC_15"><span class="toc_number toc_depth_12">16.</span> Pros y Contras de WhatsApp Plus</a></li>
                        <li><a href="#TBC_16"><span class="toc_number toc_depth_12">17.</span> Opiniones de Usuarios</a></li>
                        <li><a href="#TBC_17"><span class="toc_number toc_depth_12">18.</span> Preguntas Frecuentes</a></li>
                        <li><a href="#TBC_18"><span class="toc_number toc_depth_12">19.</span> Palabras Finales</a></li>
                      </ul>
                    </section>

                    <section class="table-content hide" v-show="tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Tabla de Contenidos
                        <img src="../assets/plus.svg" alt="más">
                      </div>
                    </section>
                  </div>

                  <h2 class="h">
                    <span id="TBC_0">Detalles de la App WhatsApp Plus</span>
                  </h2>
                  <ul>
                    <li><strong>Tamaño: </strong>77 MB</li>
                    <li><strong>Base de Usuarios: </strong>2 millones de usuarios en todo el mundo</li>
                    <li><strong>Frecuencia de Actualización: </strong>Actualizaciones regulares cada pocos meses</li>
                    <li><strong>Desarrolladores: </strong>Desarrolladores de la comunidad</li>
                    <li><strong>Disponibilidad de la App: </strong>Solo para Android, no disponible en Google Play Store</li>
                  </ul>

                  <h2 class="h">
                    <span id="TBC_1">Captura de pantalla</span>
                  </h2>

                  <div class="wp-block-image">
                    <figure class="aligncenter size-full">
                      <picture>
                        <source srcset="../assets/plus-1.webp" type="image/webp">
                        <img decoding="async" loading="lazy" width="1000" height="563" src="../assets/plus-1.webp" alt="interfaz de WhatsApp Plus" class="wp-image-48">
                      </picture>
                    </figure>
                  </div>

                  <h2 class="h">
                    <span id="TBC_2">Necesidad de WhatsApp Plus</span>
                  </h2>

                  <p>
                    WhatsApp Plus es ampliamente utilizado por usuarios que buscan características de personalización y privacidad avanzadas que no están disponibles en el WhatsApp oficial. Esto incluye un control avanzado sobre la visibilidad del chat, modificaciones en la apariencia y la capacidad de usar múltiples cuentas de manera fluida.
                  </p>

                  <h2 class="h">
                    <span id="TBC_3">Características de WhatsApp Plus</span>
                  </h2>

                  <div class="is-layout-flex wp-container-8 wp-block-columns">
                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Bloqueo de la aplicación</h3>
                      <p>
                        Mantén la aplicación segura con PIN, huella digital o bloqueo por patrón.
                      </p>

                      <h3 class="h3">Bloqueo de chat</h3>
                      <p>
                        Bloquea chats individuales con contraseñas específicas.
                      </p>

                      <h3 class="h3">Modo fantasma</h3>
                      <p>
                        Estado en línea invisible, perfecto para la privacidad.
                      </p>

                      <h3 class="h3">Modo No molestar</h3>
                      <p>
                        Desactiva el internet solo para WhatsApp Plus sin afectar otras aplicaciones.
                      </p>

                      <h3 class="h3">Enviar mensajes sin guardar el número</h3>
                      <p>
                        Envía mensajes a números desconocidos directamente.
                      </p>

                      <h3 class="h3">Ocultar escritura/grabación</h3>
                      <p>
                        Oculta el indicador de escritura o grabación en los chats.
                      </p>

                      <h3 class="h3">Cambiar temas</h3>
                      <p>
                        Accede a una extensa biblioteca de temas para personalizar.
                      </p>

                      <h3 class="h3">Cambiar fuentes</h3>
                      <p>
                        Modifica los estilos de fuente según tu preferencia.
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Ocultar los ticks azules</h3>
                      <p>
                        Lee los mensajes sin activar las confirmaciones de lectura.
                      </p>

                      <h3 class="h3">Traducción de mensajes</h3>
                      <p>
                        Traduce los mensajes en tiempo real a cualquier idioma compatible.
                      </p>

                      <h3 class="h3">Mensajes anti-eliminados</h3>
                      <p>
                        Conserva los mensajes eliminados por otros usuarios.
                      </p>

                      <h3 class="h3">Ir al primer mensaje</h3>
                      <p>
                        Vuelve al primer mensaje de cualquier chat con un clic.
                      </p>

                      <h3 class="h3">Programar mensaje</h3>
                      <p>
                        Automatiza el envío de mensajes a horas específicas.
                      </p>

                      <h3 class="h3">Respuesta automática</h3>
                      <p>
                        Configura respuestas automáticas cuando estés ocupado.
                      </p>

                      <h3 class="h3">Mensaje no leído</h3>
                      <p>
                        Resalta los mensajes no leídos en los chats.
                      </p>

                      <h3 class="h3">Descargar estado</h3>
                      <p>
                        Descarga fotos, videos y actualizaciones de estado de texto.
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_4">Nuevas características añadidas en 2024</span>
                  </h2>
                  <ul>
                    <li>Modo Fantasma</li>
                    <li>Historial de edición de mensajes</li>
                    <li>Ir al primer mensaje</li>
                    <li>Interfaz de usuario antigua</li>
                    <li>Modo Noche/Modo Claro</li>
                    <li>Selección de miembros para crear grupo</li>
                  </ul>

                  <h2 class="h">
                    <span id="TBC_5">WhatsApp Plus vs. WhatsApp oficial</span>
                  </h2>
                  <figure class="wp-block-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Característica principal</th>
                          <th>WhatsApp Plus</th>
                          <th>WhatsApp oficial</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Modo Fantasma</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ocultar estado en línea</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Bloqueo de la aplicación</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Quién puede llamarme</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Cambiar temas</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Cambiar fuentes</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Cambiar icono de los ticks</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Respuesta automática</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Programar mensaje</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mensajes anti-borrado</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Imágenes originales</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Congelar último visto</td>
                          <td>✅</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td>Servicio al cliente</td>
                          <td>❌</td>
                          <td>✅</td>
                        </tr>
                        <tr>
                          <td>Sistema</td>
                          <td>Android</td>
                          <td>Android, Web, Escritorio, iOS</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <h2 class="h">
                    <span id="TBC_6">Copia de seguridad y restauración de WhatsApp Plus</span>
                  </h2>

                  <p>
                    WhatsApp oficial permite a los usuarios hacer copias de seguridad de sus datos con diferentes frecuencias directamente en Google Drive. Sin embargo, WhatsApp Plus es una versión modificada que no puede acceder a Google Drive.
                  </p>
                  <p>
                    Los usuarios de WhatsApp Plus pueden ir al administrador de archivos de sus teléfonos Android, encontrar la ubicación de la carpeta de WhatsApp Plus y seleccionar el archivo de base de datos para cargarlo en Google Drive.
                  </p>
                  <p>
                    Entonces, ¿cómo restaurar los datos a WhatsApp oficial? Puedes reemplazar el archivo de base de datos oficial con el de WhatsApp Plus. Luego funcionará. Para más detalles, puedes leer nuestros blogs.
                  </p>

                  <h2 class="h">
                    <span id="TBC_7">Requisitos para la aplicación WhatsApp Plus</span>
                  </h2>
                  <ul>
                    <li><strong>Sistema:</strong> Android 5.0 o superior</li>
                    <li><strong>Almacenamiento:</strong> Al menos 200 MB de espacio libre</li>
                    <li><strong>No requiere root</strong></li>
                    <li><strong>Un número registrado en WhatsApp</strong></li>
                  </ul>
                  <h2 class="h">
                    <span id="TBC_8"> ¿Cómo descargar WhatsApp Plus? </span>
                  </h2>
                  <ol>
                    <li>Paso 1: Habilita <strong>Instalar desde fuentes desconocidas</strong> en los permisos especiales de las aplicaciones.</li>
                    <li>Paso 2: Descarga el archivo APK de WhatsApp Plus desde una fuente confiable.</li>
                    <li>Paso 3: Instala el archivo APK en tu dispositivo Android.</li>
                  </ol>

                  <h2 class="h">
                    <span id="TBC_9"> ¿Cómo actualizar WhatsApp Plus? </span>
                  </h2>
                  <p>
                    Las actualizaciones corrigen fallos y errores, refuerzan los protocolos anti-baneo e introducen nuevas características para mejorar el rendimiento. Aquí puedes elegir dos métodos para actualizar WhatsApp Plus.
                  </p>
                  <ul>
                    <li><strong>Método 1: </strong>Descarga la última versión del archivo APK desde un sitio confiable e instálalo para mantener la aplicación actualizada.</li>
                    <li><strong>Método 2: </strong>Verifica si la aplicación te avisa sobre una actualización de versión. Así podrás actualizar directamente.</li>
                  </ul>

                  <h2 class="h">
                    <span id="TBC_10"> Versión oficial 18.30 de WhatsApp Plus </span>
                  </h2>
                  <p>
                    La versión 18.30 es la más reciente, que ofrece una personalización mejorada, privacidad y un rendimiento optimizado. Refuerza la protección contra el baneo y soluciona algunos fallos.
                  </p>
                  <p>
                    También se ha añadido una guía para vincular WhatsApp Plus como dispositivo complementario de WhatsApp Web. Puedes consultar la guía para iniciar sesión en tu cuenta sin ser baneado.
                  </p>

                  <h2 class="h">
                    <span id="TBC_11"> WhatsApp Plus 2025 próximo </span>
                  </h2>
                  <p>
                    Los usuarios esperan que los desarrolladores eliminen las limitaciones del uso de cuentas de WhatsApp Plus 2025 impuestas por la versión oficial de WhatsApp.
                  </p>

                  <h2 class="h">
                    <span id="TBC_12"> Versiones anteriores de WhatsApp Plus </span>
                  </h2>
                  <p>
                    Las versiones anteriores notables incluyen v18.10, v18.00 y v17.85, que introdujeron características y actualizaciones significativas.
                  </p>

                  <h2 class="h">
                    <span id="TBC_13"> WhatsApp Plus para iPhone </span>
                  </h2>
                  <p>
                    Actualmente, WhatsApp Plus no está disponible en iOS debido a restricciones de la tienda de aplicaciones. Por lo tanto, los usuarios deben usar la aplicación WhatsApp Plus en Android.
                  </p>

                  <h2 class="h">
                    <span id="TBC_14"> Descargar WhatsApp Plus para PC/Portátil </span>
                  </h2>
                  <p>
                    Dado que WhatsApp Plus no está disponible para iPhone, los usuarios inteligentes encuentran una manera indirecta de instalar el archivo APK de WhatsApp Plus en PC (Windows 7, 8, 10, 11).
                  </p>
                  <p>
                    Usar Bluestacks o cualquier emulador Android de alta calidad puede ayudarte a instalar WhatsApp Plus en tu PC. Para instalar la aplicación con éxito, debes leer primero la guía del usuario de Bluestacks.
                  </p>

                  <h2 class="h">
                    <span id="TBC_15"> Pros y Contras de WhatsApp Plus </span>
                  </h2>
                  <figure class="wp-block-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Pros</th>
                          <th>Contras</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Características personalizadas para enriquecer la experiencia de mensajería</td>
                          <td>No está oficialmente soportado, riesgos de seguridad, riesgo de banear la cuenta.</td>
                        </tr>
                        <tr>
                          <td>Más opciones de privacidad para proteger la privacidad de los usuarios</td>
                          <td>Riesgo de banear la cuenta</td>
                        </tr>
                        <tr>
                          <td>Gratis para descargar e instalar la aplicación. Sin tarifas ocultas.</td>
                          <td>Modo de compañero limitado por la versión oficial</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <h2 class="h">
                    <span id="TBC_16"> Opiniones de los usuarios </span>
                  </h2>
                  <ol>
                    <li><strong>Alice: </strong>"¡Genial para la personalización, realmente disfruto los temas!"</li>
                    <li><strong>John: </strong>"¡Finalmente encontré una forma de controlar quién me ve en línea!"</li>
                  </ol>

                  <h2 class="h">
                    <span id="TBC_17"> Preguntas frecuentes </span>
                  </h2>
                  <div class="schema-faq wp-block-yoast-faq-block">
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">¿Es seguro usar WhatsApp Plus?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Es una versión no oficial y puede conllevar riesgos de seguridad, pero se usa ampliamente en todo el mundo.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">¿Puedo usar WhatsApp Plus y WhatsApp oficial en el mismo dispositivo?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Sí, con soporte para aplicaciones duplicadas en Android.
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_18"> Palabras finales </span>
                  </h2>
                  <p>
                    WhatsApp Plus es una opción atractiva para los usuarios que buscan una experiencia de mensajería más personalizable, pero los usuarios deben considerar los posibles riesgos de seguridad.
                  </p>

                  <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
                </div>
            </div>
          </article>
        </main>
      </div>
    </div>
  </div>

  <div id="snippet-box">
    <div class="snippet-title">
      Resumen
    </div>

    <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
      <div class="aio-info">
        <div class="snippet-label-img snippet-clear">
          Calificación del autor
        </div>
        <div class="snippet-data-img">
          <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"></span>
        </div>
        <div class="snippet-clear" />
        <div class="aggregate_sec" itemprop="aggregateRating" itemscope="" itemtype="https://schema.org/AggregateRating">
          <div class="snippet-label-img">
            Calificación general
          </div>
          <div class="snippet-data-img">
            <span itemprop="ratingValue">3.65</span> basado en
            <span class="rating-count" itemprop="ratingCount">46258</span>
            votos
          </div>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Nombre del software
        </div>
        <div class="snippet-data-img">
          <span itemprop="name">GBWhatsApp APK</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Sistema operativo
        </div>
        <div class="snippet-data-img">
          <span itemprop="operatingSystem">Android</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Categoría del software
        </div>
        <div class="snippet-data-img">
          <span itemprop="applicationCategory">Aplicación</span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Precio
        </div>
        <div class="snippet-data-img">
          <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
            <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
        </div>
        <div class="snippet-clear" />
        <div class="snippet-label-img">
          Página de destino
        </div>
        <div class="snippet-data-img">
          <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
        </div>
        <div class="snippet-clear" />
      </div>
    </div>
  </div>

  <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
    <footer class="site-info">
      <div class="inside-site-info grid-container">
        <div class="footer-bar">
          <a href="/privacy/">Política de privacidad</a>
        </div>
        <div class="copyright-bar">
          2025 © Todos los derechos reservados <strong>
            <router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link>
          </strong>
        </div>
      </div>
    </footer>
  </div>
  

  <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn" @click="goto('download')">
    Descargar GBWhatsApp Nuevo
  </div>

</div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import {
  allCommentList
} from '@/utlis/allCommentList.js';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data() {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'es',
    };
  },
  computed: {
    downloadURL() {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
  },
  mounted() {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    changeLanguage(lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({
        name: `${this.selectedLanguage}-gb`
      });
    },
    downloadClick() {
      this.$router.push({
        name: 'en-download'
      });
    },
    getOfficalApk(type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatapks.com',
          appName: 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload() {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    jump(url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>

<template>
<div id="app" class="site grid-container container hfeed" style="height: auto !important">
  <HeadNav selectedLanguage="es" page="download" />

  <div id="content" class="site-content" style="height: auto !important">
    <article id="article" class="center" style="height: auto !important">
      <section id="downloadpage" style="height: auto !important">
        <div style="height: 35px" aria-hidden="true" class="wp-block-spacer" />

        <div class="wp-block-image ic sdw">
          <figure class="aligncenter size-full">
            <img decoding="async" width="512" height="512" src="../assets/logo.webp" alt="GB WhatsApp APK" class="wp-image-615">
          </figure>
        </div>
        <figure class="wp-block-table is-style-regular">
          <table class="has-fixed-layout">
            <tbody>
              <tr>
                <td>Nombre de la aplicación</td>
                <td>GB WhatsApp</td>
              </tr>
              <tr>
                <td>Versión</td>
                <td>
                  <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                    18.30
                  </div>
                </td>
              </tr>
              <tr>
                <td>Tamaño</td>
                <td>75.8 MB</td>
              </tr>
              <tr>
                <td>Sistema</td>
                <td>
                  Android
                </td>
              </tr>
              <tr>
                <td>Última actualización</td>
                <td>Hace 1 día</td>
              </tr>
            </tbody>
          </table>
        </figure>

        <div id="download" class="wp-block-buttons aligncenter" style="width: 100%; display: block; text-align: center" @click="downloadfrom('bigbtn')">
          <div class="wp-block-button">
            <div class="godxthemes-btn wp-block-button__linkvk ripple">
              <i class="material-icons">
                <svg version="1.1" viewBox="0 0 35 35" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff">
                  <path d="m16 16-3.25-3.25-0.75 0.75 4.5 4.5 4.5-4.5-0.75-0.75-3.25 3.25v-11h-1v11zm5 2h6.775l-4.375-7h-5.4v-1h6l5 8v1 8h-25v-9l5-8h6v1h-5.4l-4.375 7h6.775v2c0 1.1046 0.89586 2 1.9974 2h5.0052c1.1031 0 1.9974-0.88773 1.9974-2v-2z" />
                </svg>
              </i>
              Descargar GB WhatsApp APK
            </div>
          </div>
        </div>

        <a id="abc" :href="apk" target="_self" download />
        <div class="wpxads">
          <center>
            <br>
            <div class="wp-block-buttons is-content-justification-center">
              <div class="wp-block-button">
                <a href="https://t.me/gb_download" target="_blank" class="dlbtn wp-block-button__linkvk clickable">
                  Únete al canal de Telegram de GB WhatsApp
                </a>
              </div>
            </div>
            <br>
          </center>
        </div>

        <div class="guide">
          <h2>Guía para descargar GB WhatsApp actualizado 2025</h2>
          <table class="download-table">
            <tr>
              <th>Paso 1</th>
              <td>Visita nuestro sitio web y haz clic en el botón de descarga. Serás redirigido a la página de descarga de GB WhatsApp. Luego haz clic en "Descargar ahora".</td>
            </tr>
            <tr>
              <th>Paso 2</th>
              <td>Habilita la opción para instalar aplicaciones desde fuentes desconocidas en tu teléfono, luego podrás descargar la app.</td>
            </tr>
            <tr>
              <th>Paso 3</th>
              <td>Sigue las instrucciones en pantalla para instalar <a href="https://gbapks.com.pk/" target="_blank" class="jump-url">GB WhatsApp 2025</a>. Puedes otorgar permisos ya que este paquete es seguro.</td>
            </tr>
            <tr>
              <th>Paso 4</th>
              <td>Abre la aplicación e inicia sesión.</td>
            </tr>
          </table>
          <p>
            Lee también: <router-link :to="{name:`${selectedLanguage}-blog6`}" class="jump-url">Las 10 mejores modificaciones de WhatsApp para Android en 2024 (anti-ban)</router-link>
          </p>
        </div>

        <div style="height: 15px" aria-hidden="true" class="wp-block-spacer" />
        <h2>Aplicaciones similares a GB WhatsApp</h2>
        <div class="mod-wrapper">
          <router-link class="mod-item" v-for="(item, i) in doc.modList" :key="i" :to="{name:`${selectedLanguage}-${item.name}`}">
            <img :src="item.img" :alt="item.title" class="mod-item-img" />
            <div class="mod-item-content">
              <h2 class="mod-item-title" v-if="item.dlpg_title">{{ item.dlpg_title }}</h2>
              <h2 class="mod-item-title" v-else>{{ item.title }}</h2>
            </div>
          </router-link>
        </div>

        <div style="height: 30px" aria-hidden="true" class="wp-block-spacer" />
      </section>
    </article>
  </div>
</div>
</template>


<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/download.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js'

export default {
  name: 'Download',
  data() {
    return {
      pageName: 'download',
      showapk: false,
      doc: doc,
      apk: null,
      from: 'gb',
      filename: 'gb',
      selectedLanguage: 'es',
    };
  },
  components: {
    HeadNav,
  },
  mounted() {
    this.from = this.$global.GetQueryString('from') || this.from;
    this.filename = this.$global.GetQueryString('filename') || this.filename;
    console.log(this.$global.GetQueryString('from'));
    if (this.$global.GetQueryString('from')) {
      this.getAPK();
    } else {
      this.getOfficalApk();
    }

    // window.adsTag.renderReward(54983, (rewardedStatus) => {
    // 	if (rewardedStatus) {
    // 		// 执行激励广告展示完成后操作
    // 		console.log("广告已经展示");
    // 	} else {
    // 		// 执行激励广告展示未完成操作
    // 	}
    // });

    // window.adsTag.adBreak({
    //   zoneId: 54983,
    //   type: 'reward',
    //   rewardSkipSecond: 15,
    //   adBreakDone: (viewed) => {
    //     console.log('aaa');
    //     if (viewed) {
    //       // 广告成功展示
    //       console.log('1');
    //     } else {
    //       // 广告展示失败，或由用户中止
    //       console.log('2');
    //     }
    //   },
    // });
    // this.apk = process.env.VUE_APP_GBLINK
  },
  methods: {
    downloadfrom(reason) {
      this.download();
      this.$logEvent(`download_from_${reason}`, 'download');
      // window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;
    },
    gotoblog() {
      window.location.href = '/blogs';
    },
    getAPK() {
      this.$server
        .getAPPInfo({
          promotionChannel: this.from,
          promotionName: this.filename,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.apk = res.data.apk;
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    getOfficalApk(type = 1, domain = null, appname = null) {
      this.$server
        .getOfficalApk({
          domain: domain || 'gbwhatapks.com',
          appName: appname || 'GB_gbwhatapks.com',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    download() {
      console.log(this.apk);
      // this.showapk = true
      if (this.apk) {
        this.$global.download(this.apk);
      }
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
    // download() {
    // 	console.log(this.apk);
    // 	this.$global.download(this.apk);
    // },
  },
};
</script>

<template>
  <div id="app"
    class="privacy-policy page-template-default page page-id-3 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en" page="privacy" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article id="post-3" class="post-3 page type-page status-publish">
              <div class="inside-article">
                <header class="entry-header" aria-label="Content">
                  <h1 class="entry-title" itemprop="headline">
                    Contact Us
                  </h1>
                </header>

                <div class="entry-content" itemprop="text">
                  <p>
                    If you have problem to download the GB WhatsApp APK or other WhatsApp Mods, or have any questions, feel free to contact us: <strong>contact@gbwhatapks.com</strong>.
                  </p>
                </div>

                <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Privacy Policy</a>
            |
            <a href="/contact-us/">Contact Us</a>
            |
            <a href="/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><a href="https://gbwhatapks.com/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>
    
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import HeadNav from '@/components/HeadNav.vue';

export default {
  name: 'Privacy',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'privacy',
    };
  },
  mounted () { },
};
</script>

<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="en" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Latest GB MOD APPS 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      Download
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`${selectedLanguage}-${item.name}`}">
                      Download
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>What Are WhatsApp Mod APKs?</h2>
              <p>
                While the official WhatsApp app evolves with updates, its pace and limited features often fall short of meeting user demands. To bridge this gap, skilled developers have modified the original app, integrating exciting and practical features beyond those offered by WhatsApp. As a result, popular WhatsApp Mods like GB WhatsApp mod, Fouad mod WhatsApp, and Yo WhatsApp were developed.
              </p>
              <p>
                On this page, you'll find detailed information about these WhatsApp Mod APKs. We provide safe download links, step-by-step guides, and feature introductions to help you explore and enjoy these modified apps.
              </p>
              <p>
                Feel free to download WhatsApp Mod APK you like now!
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Privacy Policy</a>
            |
            <a href="/contact-us/">Contact Us</a>
            |
            <a href="/about-us/">About Us</a>
          </div>
          <div class="copyright-bar">
            2025 © All Rights Reserved <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';


export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'en',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="id" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Aplikasi GB MOD Terbaru 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      Unduh
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`en-${item.name}`}">
                      Unduh
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>Apa Itu WhatsApp Mod APK?</h2>
              <p>
              Meskipun aplikasi resmi WhatsApp terus berkembang dengan pembaruan, kecepatannya dan fitur yang terbatas sering kali tidak memenuhi kebutuhan pengguna. Untuk mengatasi hal ini, pengembang berbakat telah memodifikasi aplikasi asli, menambahkan fitur menarik dan praktis yang melampaui apa yang ditawarkan oleh WhatsApp. Hasilnya, WhatsApp Mod populer seperti GB WhatsApp, Fouad WhatsApp, dan Yo WhatsApp dikembangkan.
              </p>
              <p>
              Di halaman ini, Anda akan menemukan informasi rinci tentang WhatsApp Mod APK ini. Kami menyediakan tautan unduhan aman, panduan langkah demi langkah, dan pengenalan fitur untuk membantu Anda menjelajahi dan menikmati aplikasi yang dimodifikasi ini.
              </p>
              <p>
              Jangan ragu untuk mengunduh WhatsApp Mod APK yang Anda suka sekarang!
              </p>

            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy/">Kebijakan Privasi</a>
          </div>
          <div class="copyright-bar">
            2025 © Semua Hak Dilindungi <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Unduh GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'id',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      let params = window.location.search;
      window.location.href = '/id/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
